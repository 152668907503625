import * as React from "react";

export const Staff = ({
    ...props
}) => (
  <svg
    width={45}
    height={45}
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={45} height={45} rx={22.5} fill="#9376E5" />
    <path
      d="M17.5 28.5C17.2167 28.5 16.9792 28.4042 16.7875 28.2125C16.5958 28.0208 16.5 27.7833 16.5 27.5V25.5H29.5V16.5H31.5C31.7833 16.5 32.0208 16.5958 32.2125 16.7875C32.4042 16.9792 32.5 17.2167 32.5 17.5V30.075C32.5 30.525 32.2958 30.8375 31.8875 31.0125C31.4792 31.1875 31.1167 31.1167 30.8 30.8L28.5 28.5H17.5ZM16.5 23.5L14.2 25.8C13.8833 26.1167 13.5208 26.1875 13.1125 26.0125C12.7042 25.8375 12.5 25.525 12.5 25.075V13.5C12.5 13.2167 12.5958 12.9792 12.7875 12.7875C12.9792 12.5958 13.2167 12.5 13.5 12.5H26.5C26.7833 12.5 27.0208 12.5958 27.2125 12.7875C27.4042 12.9792 27.5 13.2167 27.5 13.5V22.5C27.5 22.7833 27.4042 23.0208 27.2125 23.2125C27.0208 23.4042 26.7833 23.5 26.5 23.5H16.5Z"
      fill="white"
    />
  </svg>
);
