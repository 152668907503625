
export const Title = ({
    value,
    textColor = 'text-purple-primary'
}) => {
    return (
        <>
            <span className={`${textColor} text-[1.5rem] font-extrabold`}>{value}</span>
        </>
    )
}
