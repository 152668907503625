import * as React from "react";

export const Calendar = ({
    ...props
}) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Date">
      <g id="Rectangle 26">
        <path
          d="M2 6.2C2 5.0799 2 4.51984 2.21799 4.09202C2.40973 3.71569 2.71569 3.40973 3.09202 3.21799C3.51984 3 4.0799 3 5.2 3H14.8C15.9201 3 16.4802 3 16.908 3.21799C17.2843 3.40973 17.5903 3.71569 17.782 4.09202C18 4.51984 18 5.0799 18 6.2C18 6.48003 18 6.62004 17.9455 6.727C17.8976 6.82108 17.8211 6.89757 17.727 6.9455C17.62 7 17.48 7 17.2 7H2.8C2.51997 7 2.37996 7 2.273 6.9455C2.17892 6.89757 2.10243 6.82108 2.0545 6.727C2 6.62004 2 6.48003 2 6.2Z"
          fill="#9376E5"
        />
      </g>
      <g id="Rectangle 27">
        <path
          d="M2 13C2 14.8856 2 15.8284 2.58579 16.4142C3.17157 17 4.11438 17 6 17H14C15.8856 17 16.8284 17 17.4142 16.4142C18 15.8284 18 14.8856 18 13V10C18 9.5286 18 9.29289 17.8536 9.14645C17.7071 9 17.4714 9 17 9H3C2.5286 9 2.29289 9 2.14645 9.14645C2 9.29289 2 9.5286 2 10V13Z"
          fill="#9376E5"
        />
      </g>
      <path
        id="Line 1"
        d="M6 2L6 4"
        stroke="#9376E5"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        id="Line 2"
        d="M14 2L14 4"
        stroke="#9376E5"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </g>
  </svg>
);
