import React, { useState, useEffect, useRef } from 'react'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import { privateSitePrefix, staffPublicUrl } from '../../Util/Config'
import { normalizeUrl } from '../../Util/Format';
import { _ } from '../../Util/Object'
import { Redirect } from "react-router-dom";
import * as UploadFile from '../../State/UploadFile';
import * as TeleExpertise from '../../State/Staff';
import * as InvitationState from '../../State/Invitation';
import * as CustomField from '../../State/CustomField';
import * as Resources from '../../State/Resources';
import * as Login from '../../State/Login';
import * as Menu from '../../State/Menu'
import * as Ui from '../../Component/Ui'
import * as Styled from './Staff.styled'
import DatePicker from '../../Component/Ui/DatePicker';
import WarningBanner from '../../Component/Ui/WarningBanner'
import LoadingBox from '../../Component/Ui/LoadingBox'
import pluralize from '../../Util/Pluralize';
import { getManagersDisciplines } from '../../Util/TeleExpertisesHelpers';
import {
  enterField,
  leaveField,
  enterFieldsBlock,
  leaveFieldsBlock,
  keyPress,
  restoreValue,
} from '../../Util/InlineEditActions';
import Summary from './leftPanel/Summary';
import PatientsTab from './tabs/PatientsTab';
import ColleaguesTab from './tabs/ColleaguesTab';
import SchedulingTab from './tabs/SchedulingTab';
import PatientFileTab from './tabs/PatientFileTab';
import PortalTab from './tabs/PortalTab';
import SettingsTab from './tabs/SettingsTab';

const teleExpertiseName = 'staff'

const StaffComponent = (props) => {
  const alert = useAlert()
  const { match: { params: { id: teleExpertiseIdParam, sessionId: sessionIdParam } } } = props
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1)
  const currentUser = useSelector(Login.selectUser)
  const teleExpertiseId = useSelector(TeleExpertise.selectId)
  const sessionId = useSelector(TeleExpertise.selectSessionId)
  const resourceLoading = useSelector(Resources.selectLoading)
  const forms = useSelector(CustomField.selectFormFields)
  const isEditFields = useSelector(CustomField.selectIsViewerFieldEdit)
  const isBuilderFieldEdit = useSelector(CustomField.selectIsBuilderFieldEdit)
  const {
    data: {
      permissions: { value: permissions },
      owner,
      managers,
      sessionsUpcoming,
      colleagues,
      patients: patientsIds,
      organizationMembers,
      publicUri,
    },
    data,
    search,
    expertisesWithComments,
    savingSession,
    errored,
    errorMessage,
    loading,
    sending,
    pending,
    isEditMode,
    closing,
  } = useSelector(state => state.staff)
  const isInviting = useSelector(InvitationState.selectIsInviting)
  const {
    [Resources.RESOURCE_MEMBERS]: members,
    [Resources.RESOURCE_DISCIPLINES]: disciplines,
    [Resources.RESOURCE_PATIENTS]: patients,
    [Resources.RESOURCE_ORGANIZATIONS]: organizations,
  } = useSelector(Resources.selectResources)
  const defaultCanEdit = permissions?._canBeModified?.default
  const archivesByYear = useSelector(TeleExpertise.selectArchivesByYear)
  const {
    sections,
    fields,
    choices,
    error,
    errorMessage: customFieldErrorMessage,
  } = useSelector(state => state.customField)

  const managersDisciplines = getManagersDisciplines(members, managers)

  const updateField = name => e => {
    if (name === 'publicUri') {
      dispatch(TeleExpertise.updateField({ name, value: normalizeUrl(e.target.value) }))
    } else {
      dispatch(TeleExpertise.updateField({ name, value: e.target.value }))
    }
    dispatch(TeleExpertise.validateData({ name }))
  }

  const updateHourField = name => e => {
    let val = e.target.value;

    if (val.length === 2) {
      val += ':';
    }
    dispatch(TeleExpertise.updateField({ name, value: val }))
  }

  const updateSessionHourField = (e, idx) => {
    let val = e.target.value;

    if (val.length === 2) {
      val += ':';
    }
    dispatch(TeleExpertise.updateSessionHourField({ value: val, idx }))
  }

  const send = (e, selectedField) => {
    e.preventDefault()
    if (!sending) {
      dispatch(TeleExpertise.update({ teleExpertiseId, teleExpertiseName, selectedField }))
    }
  }

  useEffect(() => {
    const selectTab = props.location.search
    const activeTab = qs.parse(selectTab, { ignoreQueryPrefix: true }).tab
    if (activeTab) {
      setCurrent(Number(activeTab))
    }
  }, [props.location])

  useEffect(() => {
    dispatch(TeleExpertise.fetch({ teleExpertiseIdParam, sessionIdParam }))
    dispatch(Menu.changeActive(Menu.STAFFS))

    return () => {
      dispatch(TeleExpertise.clean())
      dispatch(CustomField.clean())
      dispatch(UploadFile.cleanAll())
    }
  }, [teleExpertiseIdParam, sessionIdParam, closing, dispatch])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  const nextSession = sessionsUpcoming.value[0] || {}
  const nextSessionDateAtMidnight = nextSession.date instanceof Date
    ? nextSession.date.getTime()
    : null

  const membersCount = [
    ...owner.value,
    ...colleagues.value,
    ...managers.value,
    ...organizationMembers.value,
  ].filter((item, idx, arr) => arr.indexOf(item) === idx).length

  const setEditField = (fieldName, value) =>
    dispatch(TeleExpertise.setEditField({ field: fieldName, value }))

  const setEditMode = (blockName, value) =>
    dispatch(TeleExpertise.setEditMode({ target: blockName, value }))

  const restoreInitialValue = (fieldName, value) =>
    dispatch(TeleExpertise.restoreInitialValue({ fieldName, value }))

  const saveField = (fieldName) =>
    dispatch(TeleExpertise.saveField({ fieldName }))

  const onEnterField = (fieldName, canEdit = defaultCanEdit) =>
    enterField(fieldName, canEdit, setEditField)

  const onEnterFieldsBlock = (blockName, canEdit = defaultCanEdit) =>
    enterFieldsBlock(blockName, canEdit, setEditMode)

  const onRestoreValue = (fieldName, initialValue) =>
    restoreValue(fieldName, data[fieldName], initialValue, setEditField, restoreInitialValue)

  const onLeaveField = (fieldName) =>
    leaveField(fieldName, data[fieldName], setEditField, saveField)

  const onLeaveFieldsBlock = (blockName, doSaveField = false) =>
    leaveFieldsBlock(blockName, teleExpertiseId, isEditMode, doSaveField, setEditMode, saveField)

  const onKeyPress = e =>
    keyPress(e, data, saveField, setEditField, restoreInitialValue)

  const navItems = [
    [<Ui.Icon.IconPatientSup />, `${patientsIds.value.length} ${pluralize('Patient', patientsIds.value.length)}`],
    [<Ui.Icon.IconDoctor />, `${membersCount} ${pluralize('Médecins', membersCount)}`],
    [<Ui.Icon.IconCalendar />, 'Programmation'],
    [<Ui.Icon.IconDocument />, 'Fiche patient'],
    [<Ui.Icon.IconPlanet />, 'Portail'],
    [<Ui.Icon.IconInfos />, 'Paramètres'],
  ]

  const publicUrl = [staffPublicUrl, publicUri?.value].join('/')
  const canEdit = permissions?._canBeModified?.default
  const refs = useRef({});

  if (closing && sessionIdParam) {
    return <Redirect to={`${privateSitePrefix}/staffs/${teleExpertiseId}`} />
  }

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.DetailsContainer>
          {loading && !errored
            ? (
              <Styled.FullSpaceContainer>
                <Ui.Global.Loader />
              </Styled.FullSpaceContainer>
            )
            : (
              <Ui.Layout.PageContentWrapper>
                <Summary
                  teleExpertiseName={teleExpertiseName}
                  teleExpertiseId={teleExpertiseId}
                  data={data}
                  loading={loading}
                  errored={errored}
                  managersDisciplines={managersDisciplines}
                  nextSession={nextSession}
                  nextSessionDateAtMidnight={nextSessionDateAtMidnight}
                  publicUrl={publicUrl}
                  DatePicker={DatePicker}
                />
                <Ui.Layout.Content hasPanel noPadding>
                  {!loading &&
                    <Ui.Layout.NavLinks
                      navItems={navItems}
                      current={current}
                      setCurrent={setCurrent}
                    />
                  }
                  {current === 1 &&
                    <WarningBanner
                      data={data}
                      currentUser={currentUser}
                      teleExpertiseId={teleExpertiseId}
                      teleExpertiseType='staffs'
                      slice={TeleExpertise}
                    />
                  }
                  <Ui.Layout.PaddedContent>
                    {!loading || errored
                      ? <PatientsTab
                        teleExpertiseName={teleExpertiseName}
                        data={data}
                        pending={pending}
                        teleExpertiseId={teleExpertiseId}
                        dispatch={dispatch}
                        current={current}
                        permissions={permissions}
                        isEditMode={isEditMode}
                        patients={patients}
                        search={search}
                        resourceLoading={resourceLoading}
                        sessionId={sessionId}
                        expertisesWithComments={expertisesWithComments}
                        onEnterFieldsBlock={onEnterFieldsBlock}
                        onLeaveFieldsBlock={onLeaveFieldsBlock}
                      />
                      :
                      <Styled.FullSpaceContainer>
                        <Ui.Global.Loader />
                      </Styled.FullSpaceContainer>
                    }
                    <ColleaguesTab
                      teleExpertiseName={teleExpertiseName}
                      data={data}
                      dispatch={dispatch}
                      current={current}
                      members={members}
                      organizations={organizations}
                      sessionId={sessionId}
                      sessionIdParam={sessionIdParam}
                      isEditMode={isEditMode}
                      search={search}
                      teleExpertiseId={teleExpertiseId}
                      resourceLoading={resourceLoading}
                      onEnterFieldsBlock={onEnterFieldsBlock}
                      onLeaveFieldsBlock={onLeaveFieldsBlock}
                      currentUser={currentUser}
                      isInviting={isInviting}
                      nextSessionDate={nextSession.dateString}
                      membersCount={membersCount}
                    />
                    <SchedulingTab
                      teleExpertiseName={teleExpertiseName}
                      alert={alert}
                      current={current}
                      data={data}
                      isEditMode={isEditMode}
                      send={send}
                      dispatch={dispatch}
                      permissions={permissions}
                      DatePicker={DatePicker}
                      teleExpertiseId={teleExpertiseId}
                      sessionIdParam={sessionIdParam}
                      archivesByYear={archivesByYear}
                      savingSession={savingSession}
                      updateHourField={updateHourField}
                      updateSessionHourField={updateSessionHourField}
                      canEdit={canEdit}
                    />
                    <PatientFileTab
                      teleExpertiseName={teleExpertiseName}
                      dispatch={dispatch}
                      teleExpertiseId={teleExpertiseId}
                      current={current}
                      isEditMode={isEditMode}
                      sections={sections}
                      fields={fields}
                      choices={choices}
                      errorMessage={customFieldErrorMessage}
                      hasError={error}
                      data={data}
                      forms={forms}
                      isEditFields={isEditFields}
                      isBuilderFieldEdit={isBuilderFieldEdit}
                      send={send}
                      onEnterFieldsBlock={onEnterFieldsBlock}
                      onLeaveFieldsBlock={onLeaveFieldsBlock}
                      canEdit={canEdit}
                    />
                    <PortalTab
                      teleExpertiseName={teleExpertiseName}
                      data={data}
                      current={current}
                      dispatch={dispatch}
                      refs={refs}
                      onEnterField={onEnterField}
                      onRestoreValue={onRestoreValue}
                      onLeaveField={onLeaveField}
                      onKeyPress={onKeyPress}
                      canEdit={canEdit}
                      updateField={updateField}
                      permissions={permissions}
                      teleExpertiseId={teleExpertiseId}
                      send={send}
                      alert={alert}
                      publicUrl={publicUrl}
                    />
                    <SettingsTab
                      teleExpertiseName={teleExpertiseName}
                      data={data}
                      teleExpertiseId={teleExpertiseId}
                      dispatch={dispatch}
                      current={current}
                      isEditMode={isEditMode}
                      updateField={updateField}
                      send={send}
                      disciplines={disciplines}
                      managersDisciplines={managersDisciplines}
                      members={members}
                      permissions={permissions}
                      search={search}
                      resourceLoading={resourceLoading}
                      currentUser={currentUser}
                      refs={refs}
                      onEnterField={onEnterField}
                      onRestoreValue={onRestoreValue}
                      onLeaveField={onLeaveField}
                      onEnterFieldsBlock={onEnterFieldsBlock}
                      onLeaveFieldsBlock={onLeaveFieldsBlock}
                      onKeyPress={onKeyPress}
                      canEdit={canEdit}
                    />
                  </Ui.Layout.PaddedContent>
                </Ui.Layout.Content>
              </Ui.Layout.PageContentWrapper>
            )
          }
        </Styled.DetailsContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

export default StaffComponent;
