import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as TeleExpertise from '../../../State/Staff';
import { privateSitePrefix } from '../../../Util/Config'
import * as Ui from '../../../Component/Ui'
import * as Styled from '../Staff.styled'
import Editable from '../../../Util/Editable'
import { fr } from '../../../Util/i18n';
import { dateToString } from '../../../Util/Format';
import pluralize from '../../../Util/Pluralize';
import { todayAtMidnight } from '../../../Util/Date';
import WithPermissions from '../../../Component/WithPermissions'

const SchedulingTab = ({
    teleExpertiseName,
    alert,
    current,
    data,
    teleExpertiseId,
    sessionIdParam,
    archivesByYear,
    send,
    permissions,
    DatePicker,
    savingSession,
    dispatch,
    isEditMode,
    updateSessionHourField,
    canEdit,
  }) => {
    const {
      sessionsUpcoming,
      nextSession,
      archive,
      archives,
      recurrence,
    } = data;
  
    const [showVisioPublicLink, setShowVisioPublicLink] = useState(false)
  
    useEffect(() => {
      if (
        current === 3 &&
        sessionsUpcoming.value.length === 0
      ) {
        dispatch(TeleExpertise.setEditMode(true))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, sessionsUpcoming.value])
  
    const onChangeSessionDate = (date, idx) => {
      dispatch(TeleExpertise.updateSessionDateField({ value: date, idx }))
    }
    const closeCurrentSession = () => {
      alert.show(`Voulez vous clôturer la séance en cours ?`, {
        onConfirm: (e) => {
          dispatch(TeleExpertise.closeCurrentSession({ teleExpertiseId }))
          e.stopPropagation()
        }
      })
    }
    const nextSessionDate = nextSession.value && dateToString(nextSession.value)
  
    const removeUpcomingSession = (idx, sessionUpcomingId) => e => {
      dispatch(TeleExpertise.removeUpcomingSession({ idx, sessionUpcomingId, teleExpertiseId }))
    }
  
    const onAddSession = () => {
      leaveOtherFieldsBlock()
  
      dispatch(TeleExpertise.addSessionDate())
    }
  
    const onEnterFieldsBlock = (sessionId, canEdit) => () => {
      leaveOtherFieldsBlock()
  
      return permissions?._canBeModified?.default || canEdit
        ? setEditFieldById(sessionId, true)
        : null
    }
  
    const leaveOtherFieldsBlock = () => {
      for (const idx in sessionsUpcoming.value) {
        if (sessionsUpcoming.value[idx].isFieldEdit) {
          if (sessionsUpcoming.value[idx].id)
            dispatch(TeleExpertise.updateSessionDate({ sessionUpcomingId: sessionsUpcoming.value[idx].id, idx }))
          else
            removeUpcomingSession(idx, sessionsUpcoming.value[idx].id)()
        }
      }
    }
  
    const openedSessionDateFields = sessionsUpcoming.value.some(session => session.isFieldEdit)
  
    const setEditFieldById = (sessionId, value) =>
      dispatch(TeleExpertise.setEditFieldById({ sessionId, isFieldEdit: value }))
  
    const refs = useRef();
  
    return (current === 3) && (
      <Styled.ProgramingSection>
        <Ui.Layout.Form onSubmit={e => send(e, 'staffSessionFields')} noValidate>
          {(data.publicVideoconferencingActive.value || data.publicVideoconferencingId.value) &&
            <Ui.Layout.BlockContainer>
              {!showVisioPublicLink
                ? <Ui.Button.BasicButton
                    onClick={() =>
                      alert.show(`Ce lien est public et ne doit être partagé qu'à des personnes de confiance. En passant par ce lien, le participant ne sera pas automatiquement indiqué comme étant présent dans le compte rendu. Voulez vous obtenir ce lien ?`, {
                        onConfirm: (e) => {
                          if (!data.publicVideoconferencingId.value) {
                            dispatch(TeleExpertise.fetchPublicVideoconferencing())
                          }
                          setShowVisioPublicLink(true)
                        }
                      })
                    }
                  >
                    Obtenir le lien visioconférence
                  </Ui.Button.BasicButton>
                : <>
                    <Ui.BasicElement.H2>
                      Lien visioconférence
                    </Ui.BasicElement.H2>
                    <Ui.Layout.FlexBox>
                      {`${window.location.origin}/videoconference/staff/${data.publicVideoconferencingId.value}`}
                    </Ui.Layout.FlexBox>
                  </>
              }
            </Ui.Layout.BlockContainer>
          }
          {archive.value
            ? null
            : <>
              <Ui.BasicElement.H2>
                {['Prochaine', 'séance', 'programmée']
                  .map(word => pluralize(word, sessionsUpcoming.value.length))
                  .join(' ')}
              </Ui.BasicElement.H2>
              {canEdit
                ? <Ui.Select.Select
                  label="Récurrence"
                  htmlFor="recurrence"
                  items={
                    ['none', 'daily', 'weekly', 'monthly', 'quarterly', 'annual']
                      .map(item => ({
                        label: fr.recurrence[item]
                          .replace(/^./, m => m.toUpperCase()),
                        value: item,
                        selected: item === recurrence.value,
                      }))
                  }
                  onChange={e => {
                    dispatch(TeleExpertise.updateData({
                      name: 'recurrence',
                      value: e.target.value,
                    }))
                    dispatch(TeleExpertise.saveField({ fieldName: 'recurrence' }))
                  }}
                />
                : <>
                  <Ui.BasicElement.P>
                    Récurrence : {recurrence.value ? fr.recurrence[recurrence.value] : 'Aucune'}
                  </Ui.BasicElement.P>
                </>
              }
              <Styled.SessionDatesContainer>
                {sessionsUpcoming.value.length > 0
                  ? sessionsUpcoming.value
                    .slice()
                    .sort((a, b) => (a.id === null) - (b.id === null) || a.date - b.date)
                    .map(({ id, dateString, date: nextSessionDate, hour: nextSessionHour, isFieldEdit }, idx) =>
                      <Editable
                        key={id || '0'}
                        title=""
                        content={
                          <Ui.Layout.FlexBox key={`date_${idx}`}>
                            {`Le ${dateString}${nextSessionHour && nextSessionHour !== '00:00' ? ` à ${nextSessionHour}` : ''}${idx === 0 ? ` (ouverte)` : ''}`}
                            {nextSessionDate && nextSessionDate.setHours(0, 0, 0, 0) === todayAtMidnight &&
                              <WithPermissions domains={['default']} permissions={permissions}>
                                <> - <Styled.CloseSessionLink onClick={e => { e.stopPropagation(); closeCurrentSession() }}>Clôturer</Styled.CloseSessionLink></>
                              </WithPermissions>
                            }
                          </Ui.Layout.FlexBox>
                        }
                        isEditMode={isFieldEdit}
                        onClick={openedSessionDateFields ? null : onEnterFieldsBlock(id)}
                        childRef={refs}
                        altContent="Aucune information n'a été renseignée."
                        canEdit={canEdit && !sessionsUpcoming.value.some(session => session.isFieldEdit)}
                        text
                      >
                        <Ui.Layout.FocusDiv ref={refs} tabIndex={idx + 35000}>
                          <WithPermissions domains={['default']} permissions={permissions}>
                            <React.Fragment key={`staff_session_${idx}`}>
                              <Styled.RowManualStaff>
                                <Ui.Layout.Column>
                                  <Ui.BasicElement.WhiteBox onClick={e => e.stopPropagation()}>
                                    <Styled.DatePickerBox>
                                      <DatePicker
                                        value={(nextSessionDate >= todayAtMidnight)
                                          ? nextSessionDate
                                          : null
                                        }
                                        clearIcon={null}
                                        calendarIcon="&#xe907;"
                                        minDate={new Date()}
                                        format="dd/MM/yyyy"
                                        locale="fr-FR"
                                        showLeadingZeros
                                        onClick={e => e.stopPropagation()}
                                        onChange={date => onChangeSessionDate(date, idx)}
                                      />
                                    </Styled.DatePickerBox>
                                  </Ui.BasicElement.WhiteBox>
                                </Ui.Layout.Column>
                                <Ui.Layout.Column>
                                  <Styled.HourInput
                                    inputRef={refs}
                                    htmlFor="hour"
                                    value={nextSessionDate >= todayAtMidnight &&
                                      nextSessionHour !== '00:00'
                                      ? nextSessionHour
                                      : ''
                                    }
                                    onChange={e => updateSessionHourField(e, idx)}
                                    placeholder="HH:MM"
                                    disabled={
                                      !nextSessionDate || nextSessionDate < todayAtMidnight
                                    }
                                    readOnly={!isEditMode}
                                    maxLength="5"
                                    error={sessionsUpcoming.errors[idx] ? sessionsUpcoming.errors[idx].hour.join(' ; ') : ''}
                                    noSubmit
                                  />
                                </Ui.Layout.Column>
                                <Ui.Layout.Column>
                                  <Ui.Layout.ActionButtonsContainer>
                                    {idx !== 0
                                      ? <Ui.Button.ActionButton
                                        type="button"
                                        onClick={removeUpcomingSession(idx, id)}
                                      >
                                        <Ui.Icon.IconTrash />
                                      </Ui.Button.ActionButton>
                                      : null
                                    }
                                    {sessionsUpcoming.value.length !== 0 && sessionsUpcoming.value[0].date &&
                                      <Ui.Button.ActionButton
                                        type="button"
                                        $isFilled
                                        $isGreen
                                        disabled={savingSession}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          dispatch(TeleExpertise.updateSessionDate({ sessionUpcomingId: id }))
                                        }}
                                      >
                                        <Ui.Icon.IconOk />
                                      </Ui.Button.ActionButton>
                                    }
                                  </Ui.Layout.ActionButtonsContainer>
                                </Ui.Layout.Column>
                              </Styled.RowManualStaff>
                            </React.Fragment>
                          </WithPermissions>
                        </Ui.Layout.FocusDiv>
                      </Editable>
                    )
                  : <Ui.BasicElement.P>Date à déterminer</Ui.BasicElement.P>
                }
              </Styled.SessionDatesContainer>
              <Ui.Layout.BlockContainer>
                {canEdit &&
                  <Ui.Button.BasicButton
                    onClick={onAddSession}
                    disabled={
                      sessionsUpcoming.value.some(session => session.id === null || session.isFieldEdit)
                    }
                  >
                    Ajouter une séance
                  </Ui.Button.BasicButton>
                }
              </Ui.Layout.BlockContainer>
              <Ui.Layout.BlockContainer>
                {sessionIdParam &&
                  <Styled.CurrentSessionLink to={`${privateSitePrefix}/${fr[teleExpertiseName].expertises}/${teleExpertiseId}?tab=1`}>
                    Séance {new Date(nextSession.value).getTime() >= todayAtMidnight
                      ? `du ${nextSessionDate}`
                      : 'à définir'
                    }
                  </Styled.CurrentSessionLink>
                }
              </Ui.Layout.BlockContainer>
            </>
          }
          <Ui.BasicElement.H2>Archives</Ui.BasicElement.H2>
          <Styled.ArchiveList>
            {Object.keys(archives.value).length > 0
              ? archivesByYear
                .map(([year, archive], i) =>
                  <div key={`archive_${year}_${i}`}>
                    <Ui.BasicElement.H2>
                      {year}
                    </Ui.BasicElement.H2>
                    {archive
                      .map((archive, j) => (
                        <li key={`archive_${i}${j}`}>
                          <Link to={`${privateSitePrefix}/${fr[teleExpertiseName].expertises}/${teleExpertiseId}/session/${archive.id}?tab=1`}>
                            Séance du {archive.endDate}
                          </Link>
                        </li>
                      ))
                      .reverse()}
                  </div>
                )
              : <Ui.BasicElement.P>Aucune archive n'a été trouvée pour ce staff.</Ui.BasicElement.P>
            }
          </Styled.ArchiveList>
        </Ui.Layout.Form>
      </Styled.ProgramingSection>
    )
}

export default SchedulingTab;
