import * as React from "react";

export const ArrowDown = ({
    ...props
}) => (
    <svg
    width={12}
    height={8}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Vector"
      d="M5.575 7.2751C5.44167 7.2751 5.31667 7.25426 5.2 7.2126C5.08333 7.17093 4.975 7.1001 4.875 7.0001L0.275 2.4001C0.0916667 2.21676 0 1.98343 0 1.7001C0 1.41676 0.0916667 1.18343 0.275 1.0001C0.458333 0.816764 0.691667 0.725098 0.975 0.725098C1.25833 0.725098 1.49167 0.816764 1.675 1.0001L5.575 4.9001L9.475 1.0001C9.65833 0.816764 9.89167 0.725098 10.175 0.725098C10.4583 0.725098 10.6917 0.816764 10.875 1.0001C11.0583 1.18343 11.15 1.41676 11.15 1.7001C11.15 1.98343 11.0583 2.21676 10.875 2.4001L6.275 7.0001C6.175 7.1001 6.06667 7.17093 5.95 7.2126C5.83333 7.25426 5.70833 7.2751 5.575 7.2751Z"
      fill="#9376E5"
    />
  </svg>
);
