import React, { useState } from 'react'
import styled from 'styled-components'
import * as Button from './Button'
import { normalizeName } from '../../Util/Format';
import { IconSave } from './Icon'

const DownloadableQrCodeComponent = ({ teleExpertiseName, children }) => {

  const onSaveCanvas = e => {
    e.preventDefault();
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download',
      `${normalizeName(`QRcode_${teleExpertiseName.split(' ').join('_')}`)
        .replace(/[^a-zA-Z]+/, '_').replace(/\./g, '')}.png`);
    let canvas = document.getElementById('qrCodeCanvas');
    let dataURL = canvas.toDataURL('image/png');
    let url = dataURL.replace(/^data:image\/png/, 'data:application/octet-stream');
    downloadLink.setAttribute('href', url);
    downloadLink.click();
  }

  const [showSaveButton, setShowSaveButton] = useState(false);

  return (
    <span
      className='cursor-pointer w-auto mx-auto mb-4'
      onMouseEnter={() => setShowSaveButton(true)}
      onMouseLeave={() => setShowSaveButton(false)}
      title="Accéder au portail"
    >
      <span className='w-[5.625rem] h-[5.625rem] relative'>
        {showSaveButton &&
          <DownloadButton
            $isFilled
            $isGreen
            onClick={onSaveCanvas}
          >
            <IconSave />
          </DownloadButton>
        }
        {children}
      </span>
    </span >
  )
}

const DownloadButton = styled(props => <Button.Button {...props} />)`
  width: 2.3125rem;
  height: 2.3125rem;
  padding: 0;
  font-weight: 600;
  position: absolute;
  left: 50%;
  margin-left: -1.09375rem;
  bottom: -1rem;
  pointer-events: auto;
  &:hover {
    color: white;
    background-color: ${props => props.theme.darkGreen}
  }
`

export default DownloadableQrCodeComponent;
