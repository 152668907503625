import React, { useRef } from 'react'
import { useAlert } from 'react-alert'
import * as TeleExpertise from '../../../State/Staff';
import * as Ui from '../../../Component/Ui'
import Editable from '../../../Util/Editable'
import { fr } from '../../../Util/i18n';
import Tags from '../../../Component/Ui/Tags';
import WithPermissions from '../../../Component/WithPermissions'
import { uniq } from '../../../Util/Object'
import { privateSitePrefix } from '../../../Util/Config'

const SettingsTab = ({
    teleExpertiseName,
    data,
    teleExpertiseId,
    current,
    send,
    updateField,
    isEditMode,
    members,
    dispatch,
    disciplines: disciplinesResource,
    managersDisciplines,
    permissions,
    search,
    resourceLoading,
    refs,
    onEnterField,
    onRestoreValue,
    onLeaveField,
    onEnterFieldsBlock,
    onLeaveFieldsBlock,
    onKeyPress,
    canEdit,
    currentUser,
  }) => {
    const {
      ownerId,
      owner,
      managers,
      pictureFileName,
      description,
      archive,
      disciplines,
      reportImageFileName,
    } = data;
  
    const alert = useAlert();
    const searchDisciplinesRef = useRef();
    const staffPictureRef = useRef();
    const reportImageRef = useRef();
    const descriptionRef = useRef();
    const tagRef = useRef();
    const searchManagersRef = useRef();
  
    const ownerData = members.find(member => member.id === Number(ownerId.value))
  
    const managersData = members
      .filter(member =>
        managers.value.includes(member.id) &&
        member.id !== Number(ownerId.value)
      )
  
    const onUpdateOwner = (colleague) =>
      dispatch(TeleExpertise.updateOwner({
        formerOwner: ownerId.value,
        colleague: {
          colleagueId: colleague.id,
          colleagueTitle: colleague.title,
          colleagueFullName: `${colleague.firstname} ${colleague.lastname}`,
        },
      }))
  
    const updateTagContent = (name, idx) => e => {
      dispatch(TeleExpertise.updateTagContent({ name, value: e.target.value, idx }))
    }
  
    const onRemovePicture = () => {
      dispatch(TeleExpertise.updateField({ name: 'pictureFileName', value: '' }))
      dispatch(TeleExpertise.saveField({ teleExpertiseId, fieldName: 'pictureFileName' }))
    }
  
    const onRemoveReportImage = () => {
      dispatch(TeleExpertise.updateField({ name: 'reportImageFileName', value: '' }))
      dispatch(TeleExpertise.saveField({ teleExpertiseId, fieldName: 'reportImageFileName' }))
    }
  
    const onCloseTeleExpertise = (e, teleExpertiseId) => {
      alert.show(`Confirmez-vous l'archivage de ${fr[teleExpertiseName].thisExpertise} ?`, {
        onConfirm: (e) => dispatch(TeleExpertise.closeExpertise(teleExpertiseId))
      })
    }
    const onOpenTeleExpertise = (e, teleExpertiseId) => {
      alert.show(`Confirmez-vous la ré-ouverture de ${fr[teleExpertiseName].thisExpertise} ?`, {
        onConfirm: (e) => dispatch(TeleExpertise.openExpertise(teleExpertiseId))
      })
    }
  
    const onAddTag = () => {
      dispatch(TeleExpertise.addTag())
      tagRef?.current?.focus()
    }
  
    const onRemoveTag = idx => e => {
      dispatch(TeleExpertise.removeTag({ idx }))
      tagRef?.current?.focus()
    }
  
    const onToggleDisciplines = (id, toggled, name = 'disciplines', teleExpertiseId) => {
      dispatch(TeleExpertise.toggle({ id, toggled, name, teleExpertiseId }))
    }
  
    const onToggleManagers = (id, toggled, name = 'managers') => {
      dispatch(TeleExpertise.toggle({ id, toggled, name }))
      toggled
        ? dispatch(TeleExpertise.addManager({ id }))
        : dispatch(TeleExpertise.removeManager({ id }))
    }
  
    return (current === 6) && (
      <Ui.Layout.Section>
        <Ui.Layout.Form onSubmit={send} noValidate>
          <Ui.Layout.Row>
            <Ui.Form.EditableTextInput
              name="name"
              label={`Nom${isEditMode ? ' *' : ''}`}
              data={data}
              updateField={updateField}
              onEnterField={onEnterField}
              onLeaveField={onLeaveField}
              onRestoreValue={onRestoreValue}
              onKeyPress={onKeyPress}
              refs={refs}
              canEdit={canEdit}
            />
          </Ui.Layout.Row>
          <Ui.BasicElement.ViewTitleContainer>
            <Ui.BasicElement.H3>Illustration</Ui.BasicElement.H3>
          </Ui.BasicElement.ViewTitleContainer>
          <Ui.Layout.BlockContainer>
            <Ui.UploadFile.ImagesUpload
              name="staff-picture"
              id="staff-picture"
              label=""
              endpoint={`/${teleExpertiseName}s/${teleExpertiseId}/add-picture`}
              removeEndpoint={`/${teleExpertiseName}s/${teleExpertiseId}/remove-picture`}
              value={pictureFileName.value ? [{ fileName: pictureFileName.value }] : []}
              validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
              previewUri={`/${teleExpertiseName}s/picture`}
              inputRef={staffPictureRef}
              onRemove={onRemovePicture}
              sliceToUpdate={TeleExpertise}
              disabled={!canEdit}
            />
          </Ui.Layout.BlockContainer>
          <Ui.Layout.Row>
            <Editable
              title="Description"
              text={description.value}
              isEditMode={description.isFieldEdit}
              onClick={onEnterField('description', canEdit)}
              onBlur={onLeaveField('description')}
              childRef={descriptionRef}
              altText={`${canEdit ? 'Ajouter une description' : 'Aucune description n\'a été défini'} pour ${fr[teleExpertiseName].thisExpertise}`}
              canEdit={canEdit}
            >
              <Ui.Form.TextAreaInputResizable
                name="description"
                label="Description"
                value={description.value}
                onChange={updateField('description')}
                onBlur={onLeaveField('description')}
                onRestoreValue={onRestoreValue('description', description.initialValue)}
                error={description.errors[0]}
                inputRef={descriptionRef}
              />
            </Editable>
          </Ui.Layout.Row>
          <Ui.Layout.BlockContainer>
            <Tags
              TeleExpertise={TeleExpertise}
              teleExpertiseName={teleExpertiseName}
              data={data}
              type="tag"
              label="mot-clé"
              isEditMode={isEditMode.tags}
              onAddItem={onAddTag}
              onRemoveItem={(idx) => onRemoveTag(idx)}
              onUpdateItemContent={(type, idx) => updateTagContent(type, idx)}
              onEnterFieldsBlock={onEnterFieldsBlock('tags', canEdit)}
              onLeaveFieldsBlock={onLeaveFieldsBlock('tags', true)}
              childRef={tagRef}
              canEdit={canEdit}
            />
          </Ui.Layout.BlockContainer>
          <Ui.Layout.BlockContainer>
            <Editable
              title=""
              content={
                [...managersDisciplines, ...disciplines.value].filter(uniq).length > 0 &&
                <Ui.Layout.FlexBox flow="column wrap">
                  <Ui.BasicElement.H3>Disciplines</Ui.BasicElement.H3>
                  <Ui.Layout.ColleagueCardsContainer>
                    {[...managersDisciplines, ...disciplines.value]
                      .filter(uniq)
                      .map((discipline, i) =>
                        <Ui.Form.CheckboxLarge
                          key={`discipline_${i}`}
                          name="discipline"
                          label={discipline}
                          disabled={!canEdit}
                        >
                          <Ui.SearchBar.DefaultCheckboxIcon
                          />
                        </Ui.Form.CheckboxLarge>
                      )}
                  </Ui.Layout.ColleagueCardsContainer>
                </Ui.Layout.FlexBox>
              }
              isEditMode={isEditMode.disciplines}
              onClick={onEnterFieldsBlock('disciplines', canEdit)}
              onBlur={onLeaveFieldsBlock('disciplines')}
              altContent={
                <>
                  <Ui.BasicElement.H3>Disciplines</Ui.BasicElement.H3>
                  {`${canEdit ? 'Ajouter une discipline' : 'Aucune discipline n\'est associée'} à ${fr[teleExpertiseName].thisExpertise}`}
                </>
              }
              childRef={searchDisciplinesRef}
              canEdit={canEdit}
            >
              <Ui.Layout.FocusDiv ref={searchDisciplinesRef} tabIndex="1" >
                <Ui.BasicElement.H3>Disciplines</Ui.BasicElement.H3>
                <Ui.Form.InputContainer>
                  <Ui.SearchBar.SearchAndCheck
                    name="disciplines"
                    query={search.disciplines.value}
                    TeleExpertise={TeleExpertise}
                    teleExpertiseName={teleExpertiseName}
                    resultSet={[...disciplinesResource, ...managersDisciplines]}
                    list={[...managersDisciplines, ...disciplines.value].filter(uniq)}
                    loading={resourceLoading.disciplines}
                    checkboxDisabled={discipline => managersDisciplines.includes(discipline)}
                    onToggle={onToggleDisciplines}
                  />
                </Ui.Form.InputContainer>
              </Ui.Layout.FocusDiv>
            </Editable>
          </Ui.Layout.BlockContainer>
          <Ui.Layout.BlockContainer>
            <Ui.BasicElement.H3>
              Responsable
            </Ui.BasicElement.H3>
            <Ui.Colleague.Colleague
              to={`${privateSitePrefix}/medecins/${ownerId.value}`}
              colleague={ownerData}
              currentUser={currentUser}
            />
          </Ui.Layout.BlockContainer>
          <Ui.Layout.BlockContainer>
            <Editable
              content={
                managers.value.length > 0
                  ? <Ui.Layout.FlexBox flow="column wrap">
                    <Ui.BasicElement.H3>Administrateurs</Ui.BasicElement.H3>
                    <Ui.Layout.ColleagueCardsContainer>
                      {managersData
                        .map((colleague, i) =>
                          <Ui.Colleague.Colleague
                            key={`colleague_${i}`}
                            to={`${privateSitePrefix}/medecins/${colleague.id}`}
                            colleague={colleague}
                            currentUser={currentUser}
                            onUpdateOwner={(colleague, ownerId) => onUpdateOwner(colleague, ownerId)}
                            teleExpertiseOwnerId={ownerId.value}
                            permissions={permissions}
                          />
                        )
                      }
                    </Ui.Layout.ColleagueCardsContainer>
                  </Ui.Layout.FlexBox>
                  : null
              }
              isEditMode={isEditMode.managers}
              onClick={onEnterFieldsBlock('managers', canEdit)}
              onBlur={onLeaveFieldsBlock('managers')}
              altContent={
                <>
                  <Ui.BasicElement.H3>Administrateurs</Ui.BasicElement.H3>
                  {canEdit
                    ? 'Ajouter un administrateur'
                    : `Aucun administrateur n'est associé à ${fr[teleExpertiseName].thisExpertise}`
                  }
                </>
              }
              childRef={searchManagersRef}
              canEdit={canEdit}
            >
              <Ui.Layout.FocusDiv ref={searchManagersRef} tabIndex="16554" >
                <Ui.BasicElement.H3>Administrateurs</Ui.BasicElement.H3>
                <Ui.Form.InputContainer>
                  <Ui.SearchBar.SearchAndCheck
                    name="managers"
                    query={search.managers.value}
                    TeleExpertise={TeleExpertise}
                    resultSet={members}
                    list={[...owner.value, ...managers.value].filter(uniq)}
                    loading={resourceLoading.managers}
                    checkboxDisabled={manager => manager.id === owner.value[0]}
                    onToggle={onToggleManagers}
                  />
                  {managers.errors[0] &&
                    <Ui.Form.FieldErrorMessage>{managers.errors[0]}</Ui.Form.FieldErrorMessage>
                  }
                </Ui.Form.InputContainer>
              </Ui.Layout.FocusDiv>
            </Editable>
          </Ui.Layout.BlockContainer>
          <Ui.BasicElement.ViewTitleContainer>
            <Ui.BasicElement.H3>En-tête de compte-rendu</Ui.BasicElement.H3>
          </Ui.BasicElement.ViewTitleContainer>
          <Ui.Layout.BlockContainer>
            <Ui.UploadFile.ImagesUpload
              label=""
              name="heading-documents"
              id="heading-documents"
              htmlFor="heading-documents"
              multiple={false}
              endpoint={`/${teleExpertiseName}s/${teleExpertiseId}/reportImage`}
              removeEndpoint={`/${teleExpertiseName}s/${teleExpertiseId}/reportImage`}
              value={reportImageFileName.value ? [{ fileName: reportImageFileName.value }] : []}
              validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
              previewUri={`/${teleExpertiseName}s/reportImage`}
              inputRef={reportImageRef}
              onRemove={onRemoveReportImage}
              isEditMode={isEditMode.headinDocuments}
              disabled={!canEdit}
            />
          </Ui.Layout.BlockContainer>
          <Ui.BasicElement.H2>
            Statut de {fr[teleExpertiseName].thisExpertise} :{'\u00A0'}
            {archive.value ? ' inactif' : 'actif'}
          </Ui.BasicElement.H2>
          <Ui.Layout.BlockContainer>
            <WithPermissions to={['archived']} permissions={permissions}>
              {archive.value
                ? <Ui.Button.BasicButton
                  onClick={(e) => onOpenTeleExpertise(e, teleExpertiseId)}
                >
                  Ré-ouvrir{'\u00A0'}{fr[teleExpertiseName].thisExpertise}
                </Ui.Button.BasicButton>
                : <Ui.Button.BasicButton
                  onClick={(e) => onCloseTeleExpertise(e, teleExpertiseId)}
                >
                  Archiver{'\u00A0'}{fr[teleExpertiseName].thisExpertise}
                </Ui.Button.BasicButton>
              }
            </WithPermissions>
          </Ui.Layout.BlockContainer>
        </Ui.Layout.Form>
      </Ui.Layout.Section >
    )
}

export default SettingsTab;
