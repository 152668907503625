import React, { useRef } from 'react'
import * as TeleExpertise from '../../../State/Staff';
import { privateSitePrefix, apiUrl } from '../../../Util/Config'
import * as Ui from '../../../Component/Ui'
import WithPermissions from '../../../Component/WithPermissions'
import * as Styled from '../Staff.styled'
import Editable from '../../../Util/Editable'
import { fr } from '../../../Util/i18n';

const PatientsTab = ({
    teleExpertiseName,
    current,
    data,
    pending,
    patients: patientsResource,
    permissions,
    dispatch,
    search,
    isEditMode,
    resourceLoading,
    teleExpertiseId,
    sessionId,
    expertisesWithComments,
    onEnterFieldsBlock,
    onLeaveFieldsBlock,
  }) => {
    const {
      labels,
      patients,
      isOutdated,
      archive,
      patientsLocked,
      currentSessionPatientIds,
    } = data;
  
    const canEdit = permissions?._canBeModified?.patients && !isOutdated.value && !archive.value
    const patientSelection = patientsResource.filter(patient => patients.value.includes(patient.id))
    const searchPatientsRef = useRef()
  
    const onCopyAllPatients = () =>
      dispatch(TeleExpertise.allPatientsToCurrentSession({ teleExpertiseId, sessionId, patients: patients.value }))
  
    const addToCurrentSession = patient => e =>
      isOutdated.value
        ? dispatch(TeleExpertise.addPatientToCurrentSession({ id: patient.id, teleExpertiseId }))
        : null
  
    const isInCurrentSession = id => currentSessionPatientIds.value.includes(id)
  
    const hasPatientsToReport = !patientSelection.every(patient => isInCurrentSession(patient.id))
  
    const patientLink = patientId => `${privateSitePrefix}/patients/${patientId}/${teleExpertiseName}/${teleExpertiseId}/staffSession/${sessionId}`
  
    const patientList = patientSelection
      .slice()
      .sort((a, b) => a.pendingPatient - b.pendingPatient)
      .map((patient, i) =>
        <Ui.Card.PatientCard
          key={`patient_card_${i}`}
          to={patientLink(patient.id)}
          addToCurrentSession={addToCurrentSession(patient)}
          canAddToCurrentSession={!archive.value && isOutdated.value && !isInCurrentSession(patient.id)}
          hasExpertiseComment={
            Object
              .keys(expertisesWithComments)
              .includes(String(patient.id)) &&
            expertisesWithComments[patient.id]
          }
          isLocked={patientsLocked.value.includes(patient.id)}
          {...patient}
          labels={patient.labels?.length > 0 && patient.labels.filter(patientLabel => labels.value.map((label) => label.content).includes(patientLabel.content))}
        />
      )
  
    return (current === 1)
      ? <Ui.Layout.Section>
        <WithPermissions domains={['patients']} permissions={permissions}>
          <Styled.HeaderButtons>
            {isOutdated.value &&
              <Ui.Button.BasicButton
                isGreen
                onClick={onCopyAllPatients}
                disabled={pending || patientSelection.length === 0 || !hasPatientsToReport || archive.value}
              >
                {pending === true
                  ? <Ui.Global.Loader />
                  : <Ui.Icon.IconPatientSup />
                }
                Reporter tous les patients
              </Ui.Button.BasicButton>
            }
            <a
              rel="noopener noreferrer"
              download
              href={`${apiUrl}/${teleExpertiseName}s/report-download/${teleExpertiseId}/session/${sessionId}`}>
              <Ui.Button.BasicButton
                isGreen
                disabled={!Object.values(expertisesWithComments).some(el => el)}
              >
                <Ui.Icon.IconDocument />
                Compte-rendu
              </Ui.Button.BasicButton>
            </a>
            <a
              rel="noopener noreferrer"
              download
              href={`${apiUrl}/${teleExpertiseName}s/export-patients/${teleExpertiseId}/session/${sessionId}`}>
              <Ui.Button.BasicButton
                isGreen
                disabled={patientSelection.length === 0}
              >
                <Ui.Icon.IconDocument />
                Export des patients
              </Ui.Button.BasicButton>
            </a>
          </Styled.HeaderButtons>
        </WithPermissions>
  
        <Ui.Layout.Section>
          <Editable
            content={
              !isEditMode.addPatient &&
              patientSelection.length > 0 &&
              <Ui.Layout.FlexBox flow="column wrap">
                <Ui.BasicElement.H2>Patients</Ui.BasicElement.H2>
                {canEdit &&
                  <Ui.BasicElement.P>
                    Cliquez pour ajouter ou supprimer un patient
                  </Ui.BasicElement.P>
                }
                <Ui.Layout.ColleagueCardsContainer>
                  {patientList}
                </Ui.Layout.ColleagueCardsContainer>
              </Ui.Layout.FlexBox>
            }
            description=""
            isEditMode={isEditMode.addRemovePatient}
            onClick={onEnterFieldsBlock('addRemovePatient', canEdit)}
            onBlur={onLeaveFieldsBlock('addRemovePatient')}
            altContent={
              <>
                <Ui.BasicElement.H2>Patients</Ui.BasicElement.H2>
                <Ui.BasicElement.P>
                  {canEdit
                    ? 'Cliquez pour ajouter ou supprimer un patient'
                    : `Aucun de vos patients n'est associé à ${fr[teleExpertiseName].thisExpertise}`
                  }
                </Ui.BasicElement.P>
              </>
            }
            childRef={searchPatientsRef}
            canEdit={canEdit}
          >
            <Ui.Layout.FocusDiv ref={searchPatientsRef} tabIndex="1654" >
              <Ui.BasicElement.H2>Patients</Ui.BasicElement.H2>
              <Ui.Form.InputContainer>
                <Ui.SearchBar.SearchAndCheck
                  name="patients"
                  data={data}
                  query={search.patients.value}
                  TeleExpertise={TeleExpertise}
                  teleExpertiseName={teleExpertiseName}
                  teleExpertiseId={teleExpertiseId}
                  sessionId={sessionId}
                  resultSet={patientsResource}
                  list={patients.value}
                  loading={resourceLoading.patients}
                  hasNewPatientButton
                />
              </Ui.Form.InputContainer>
            </Ui.Layout.FocusDiv>
          </Editable>
        </Ui.Layout.Section>
  
      </Ui.Layout.Section >
      : null
};

export default PatientsTab;
