import React, { useRef } from 'react'
import * as TeleExpertise from '../../../State/Staff';
import * as Ui from '../../../Component/Ui'
import * as Styled from '../Staff.styled'
import Editable from '../../../Util/Editable'
import { fr } from '../../../Util/i18n';
import { _ } from '../../../Util/Object'

const PatientFileTab = ({
    teleExpertiseName,
    dispatch,
    current,
    data,
    forms,
    isEditFields,
    isEditMode,
    isBuilderFieldEdit,
    sections,
    fields,
    choices,
    errorMessage,
    hasError,
    teleExpertiseId,
    send,
    onEnterFieldsBlock,
    onLeaveFieldsBlock,
    canEdit,
  }) => {
    const {
      labels,
      currentUserAdmin: isCurrentUserAdmin,
    } = data;
    const updateLabelContent = (name, idx) => e => {
      dispatch(TeleExpertise.updateLabelContent({ name, value: e.target.value, idx }))
    }
  
    const updateLabelColor = (name, idx) => e => {
      dispatch(TeleExpertise.updateLabelColor({ name, value: e.target.value, idx }))
    }
  
    const labelColors = (label) => ['gray', 'black', 'green', 'blue', 'purple', 'orange', 'red', 'yellow']
      .map(item => ({
        label: fr.colors[item]
          .replace(/^./, m => m.toUpperCase()),
        value: item,
        selected: item === label.color,
        color: item
      }))
  
    const labelRef = useRef();
  
    return (current === 4) &&
      <Ui.Layout.Section>
        <Ui.Layout.Form onSubmit={e => send(e, 'labels')} noValidate>
          <Ui.BasicElement.H2>
            Etiquettes
          </Ui.BasicElement.H2>
          <Ui.Layout.BlockContainer>
            <Editable
              content={labels.value.length > 0
                ? <Ui.Tag.TagsContainer>
                  {labels.value.map((label, i) =>
                    <Ui.Tag.Tag
                      key={`label_${i}`}
                      bgColor={label.color}
                      disabled={!canEdit}
                    >
                      <span>{label.content}</span>
                    </Ui.Tag.Tag>
                  )}
                </Ui.Tag.TagsContainer>
                : canEdit
                  ? <Ui.Layout.FlexBox>Ajouter des étiquettes à {fr[teleExpertiseName].thisExpertise}</Ui.Layout.FlexBox>
                  : <Ui.BasicElement.P>Aucune étiquette n'est associée à {fr[teleExpertiseName].thisExpertise}</Ui.BasicElement.P>
              }
              isEditMode={isEditMode.labels}
              onClick={onEnterFieldsBlock('labels', canEdit)}
              onBlur={labels.value.length > 0 ? onLeaveFieldsBlock('labels', true) : onLeaveFieldsBlock('labels')}
              childRef={labelRef}
              canEdit={canEdit}
            >
              <Ui.Layout.FocusDiv ref={labelRef} tabIndex="1785">
                <Styled.LabelsSection>
                  {labels.value.map((label, idx, labels) =>
                    <Ui.Form.LabelsContainer key={`label_${idx}`}>
                      <Ui.Form.LabelContainer>
                        <Ui.Form.TextInput
                          htmlFor={`label_${idx}`}
                          value={label.content}
                          onChange={updateLabelContent('label', idx)}
                          noSubmit
                        />
                        <Ui.Select.Select
                          label="Couleur"
                          htmlFor="Couleur"
                          items={labelColors(label)}
                          onChange={updateLabelColor('label', idx)}
                        />
                      </Ui.Form.LabelContainer>
                      <Ui.Layout.ActionButtonsContainer>
                        <Ui.Button.ActionButton
                          $isGreen
                          onClick={(e) => {
                            dispatch(TeleExpertise.removeLabel({ idx, id: label.id, fieldName: 'labels' }))
                            labelRef?.current?.focus()
                          }}
                        >
                          <Ui.Icon.IconTrash />
                        </Ui.Button.ActionButton>
                      </Ui.Layout.ActionButtonsContainer>
                    </Ui.Form.LabelsContainer>
                  )}
                  <Styled.LabelButtonContainer>
                    <Ui.Button.ActionButton
                      $isGreen
                      onClick={(e) => {
                        dispatch(TeleExpertise.addLabel({ content: '', color: 'gray' }))
                        labelRef?.current?.focus()
                      }}
                      disabled={
                        labels.value.length !== 0 &&
                        _.lastElement(labels.value).content.length === 0}
                    >
                      <Ui.Icon.IconPlus />
                    </Ui.Button.ActionButton>
                    {labels.value.length > 0 &&
                      <Ui.Button.BasicButton
                        isGreen
                        isFilled
                        onClick={onLeaveFieldsBlock('labels', true)}
                      >
                        Valider
                      </Ui.Button.BasicButton>
                    }
                  </Styled.LabelButtonContainer>
                </Styled.LabelsSection>
              </Ui.Layout.FocusDiv>
            </Editable>
          </Ui.Layout.BlockContainer>
        </Ui.Layout.Form>
        {isCurrentUserAdmin.value
          ? <Ui.CustomFieldBuilder
            form={forms[0]}
            teleExpertiseType='staffs'
            teleExpertiseId={teleExpertiseId}
            errorMessage={errorMessage}
            hasError={hasError}
            isBuildMode={true}
            isBuilderFieldEdit={isBuilderFieldEdit}
          />
          : <Ui.CustomFieldViewer
            data={{ sections, fields, choices }}
            forms={forms}
            isEditFields={isEditFields}
            isUseMode={true}
            isBuildMode={true}
          />
        }
      </Ui.Layout.Section >
}

export default PatientFileTab;
