import React, { useRef } from 'react'
import { useSelector } from 'react-redux';
import { staffPublicUrl } from '../../../Util/Config'
import * as TeleExpertise from '../../../State/Staff';
import { normalizeUrl } from '../../../Util/Format';
import ImageTool from '../../../Component/Ui/ImageTool';
import * as Ui from '../../../Component/Ui'
import * as Styled from '../Staff.styled'
import Editable from '../../../Util/Editable'
import { fr } from '../../../Util/i18n';

const PortalTab = ({
    teleExpertiseName,
    data,
    send,
    current,
    dispatch,
    alert,
    refs,
    onEnterField,
    onRestoreValue,
    onLeaveField,
    onKeyPress,
    canEdit,
    updateField,
    teleExpertiseId,
    publicUrl,
  }) => {
    const {
      publicUri,
      portalHeaderImage,
      portalHeaderImageStretch,
      portalHeaderImageAlign,
      portalAbout,
      portalDocuments,
    } = data;
  
    const publicUriRef = useRef();
    const portalPictureRef = useRef();
    const portalAboutRef = useRef();
    const uploadData = useSelector(state => state.uploadFile.data)
    const uploadFilePortalHeaderImage = uploadData &&
      uploadData['portal-header-image'] &&
      uploadData['portal-header-image'].value
      ? Object.values(uploadData['portal-header-image'].value)[0]
      : {}
    const uploadFilePortalHeaderImageFileName = uploadFilePortalHeaderImage?.fileName
  
    const onRemovePortalHeaderImage = (e) => {
      dispatch(TeleExpertise.updateField({ name: 'portalHeaderImage', value: '' }))
      dispatch(TeleExpertise.saveField({ teleExpertiseId, fieldName: 'portalHeaderImage' }))
    }
  
    const onDocumentRemove = ({ id }) =>
      dispatch(TeleExpertise.removePortalDocument(id));
  
    const onAlertBeforeUrlUpdate = () =>
      alert.show(`Vous vous apprêtez à modifier l'URL. Vos correspondants ne pourront plus se connecter sur l'ancienne adresse`, {
        onConfirm: onEnterField('publicUri', canEdit)
      })
  
    return (current === 5) && (
      <Ui.Layout.Section>
        <Ui.Layout.Form onSubmit={send} noValidate>
          <Ui.BasicElement.H2>
            Connexion
          </Ui.BasicElement.H2>
          <Ui.BasicElement.H3>
            Url du portail
          </Ui.BasicElement.H3>
          <Ui.BasicElement.P>
            Personnalisez puis partagez cette adresse internet pour que vos correspondants accèdent directement à votre téléexpertise.
          </Ui.BasicElement.P>
          <Editable
            text={publicUrl}
            isEditMode={publicUri.isFieldEdit}
            onClick={onAlertBeforeUrlUpdate}
            onBlur={onLeaveField('publicUri')}
            childRef={publicUriRef}
            canEdit={canEdit}
          >
            <Ui.Layout.PublicUriContainer flow="column wrap">
              <Ui.Layout.FlexBox flow="row wrap">
                <Ui.BasicElement.P>{`${staffPublicUrl}/`}</Ui.BasicElement.P>
                <div>
                  <Ui.Form.TextInput
                    name="publicUri"
                    value={normalizeUrl(publicUri.value)}
                    onChange={updateField('publicUri')}
                    onBlur={onLeaveField('publicUri')}
                    onRestoreValue={onRestoreValue('publicUri', publicUri.initialValue)}
                    onKeyDown={onKeyPress}
                    error={publicUri.errors[0]}
                    refs={refs}
                    tabIndex="4"
                  />
                </div>
              </Ui.Layout.FlexBox>
            </Ui.Layout.PublicUriContainer>
          </Editable>
          <Styled.ShowPortalButton>
            <a href={publicUrl} onClick={e => e.stopPropagation()} target="_blank" rel="noreferrer">
              <Ui.Button.BasicButton isSmall isFilled isGreen>
                Voir le portail
              </Ui.Button.BasicButton>
            </a>
          </Styled.ShowPortalButton>
          <Ui.BasicElement.H2>
            Personnalisation
          </Ui.BasicElement.H2>
          <Ui.Layout.FlexBox>
            <Ui.Layout.BlockContainer>
              <Ui.BasicElement.ViewTitleContainer>
                <Ui.BasicElement.H3>Image d'en-tête</Ui.BasicElement.H3>
              </Ui.BasicElement.ViewTitleContainer>
              <Ui.UploadFile.ImagesUpload
                label=""
                name="portal-header-image"
                id="portal-header-image"
                htmlFor="portal-header-image"
                multiple={false}
                endpoint={`/${teleExpertiseName}s/${teleExpertiseId}/add-portal-header-image`}
                removeEndpoint={`/${teleExpertiseName}s/${teleExpertiseId}/remove-portal-header-image`}
                onRemove={onRemovePortalHeaderImage}
                inputRef={portalPictureRef}
                value={portalHeaderImage.value ? [{ fileName: portalHeaderImage.value }] : []}
                validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
                previewUri={`/${teleExpertiseName}s/portal-header-image`}
                disabled={!canEdit}
              />
            </Ui.Layout.BlockContainer>
            <Ui.Layout.BlockContainer>
              {(uploadFilePortalHeaderImageFileName || portalHeaderImage.value) &&
                <>
                  <ImageTool
                    alignKey='portalHeaderImageAlign'
                    stretchKey='portalHeaderImageStretch'
                    data={data}
                    slice={TeleExpertise}
                    isStretched={portalHeaderImageStretch.value}
                    alignment={portalHeaderImageAlign.value}
                    image={uploadFilePortalHeaderImageFileName || portalHeaderImage.value}
                    isEditMode={true}
                    teleExpertiseName={teleExpertiseName}
                    teleExpertiseId={teleExpertiseId}
                    endpointPath='portal-header-image'
                    canEdit={canEdit}
                  />
                </>
              }
            </Ui.Layout.BlockContainer>
          </Ui.Layout.FlexBox>
          <Ui.BasicElement.H2>
            Contenus
          </Ui.BasicElement.H2>
          <Ui.BasicElement.P>
          </Ui.BasicElement.P>
          <Ui.Layout.BlockContainer>
            <Editable
              title="A propos"
              text={portalAbout.value}
              isEditMode={portalAbout.isFieldEdit}
              onClick={onEnterField('portalAbout', canEdit)}
              onBlur={onLeaveField('portalAbout')}
              childRef={portalAboutRef}
              altText={`${canEdit ? 'Ajouter un a propos' : 'Aucun à propos n\'a été défini'} pour ${fr[teleExpertiseName].thisExpertise}`
              }
              canEdit={canEdit}
            >
              <Ui.Form.TextAreaInputResizable
                name="portalAbout"
                label="A propos"
                value={portalAbout.value}
                onChange={updateField('portalAbout')}
                onBlur={onLeaveField('portalAbout')}
                onRestoreValue={onRestoreValue('portalAbout', portalAbout.initialValue)}
                error={portalAbout.errors[0]}
                inputRef={portalAboutRef}
              />
            </Editable>
          </Ui.Layout.BlockContainer>
          <Ui.Layout.BlockContainer>
            <Ui.BasicElement.H3>
              Documents
            </Ui.BasicElement.H3>
            <Ui.UploadFile.DocumentsUpload
              name={`portal-documents`}
              validationRules={["noLargeFiles", "noEmptyFiles", "acceptedDocumentFormat"]}
              label=""
              endpoint={`/tele-expertises/portal/add/${teleExpertiseName}/${teleExpertiseId}`}
              apiPath="/tele-expertises/portal/assets/"
              value={portalDocuments.value}
              onRemove={onDocumentRemove}
              isEditMode={true}
              disabled={!canEdit}
            />
          </Ui.Layout.BlockContainer>
        </Ui.Layout.Form>
      </Ui.Layout.Section>
    )
}

export default PortalTab;