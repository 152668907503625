import * as React from "react";

export const Download = ({
    color = '#9376E5',
    ...props
}) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 14.2866V14.7866C2.5 16.2857 3.71524 17.5009 5.21431 17.5009H14.7857C16.2848 17.5009 17.5 16.2857 17.5 14.7866V14.2866"
      stroke={color}
      strokeWidth={2.71431}
      strokeLinecap="round"
    />
    <path
      d="M6.25 8.92863L10 12.6787M10 12.6787L13.75 8.92863M10 12.6787V2.5"
      stroke={color}
      strokeWidth={2.71431}
      strokeLinecap="round"
    />
  </svg>
);
