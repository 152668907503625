import * as React from "react";

export const ArrowRight = ({
    ...props
}) => (
    <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.9001 11.9998L9.0001 8.0998C8.81676 7.91647 8.7251 7.68314 8.7251 7.3998C8.7251 7.11647 8.81676 6.88314 9.0001 6.6998C9.18343 6.51647 9.41676 6.4248 9.7001 6.4248C9.98343 6.4248 10.2168 6.51647 10.4001 6.6998L15.0001 11.2998C15.1001 11.3998 15.1709 11.5081 15.2126 11.6248C15.2543 11.7415 15.2751 11.8665 15.2751 11.9998C15.2751 12.1331 15.2543 12.2581 15.2126 12.3748C15.1709 12.4915 15.1001 12.5998 15.0001 12.6998L10.4001 17.2998C10.2168 17.4831 9.98343 17.5748 9.7001 17.5748C9.41676 17.5748 9.18343 17.4831 9.0001 17.2998C8.81676 17.1165 8.7251 16.8831 8.7251 16.5998C8.7251 16.3165 8.81676 16.0831 9.0001 15.8998L12.9001 11.9998Z"
      fill="#9376E5"
    />
  </svg>
);
